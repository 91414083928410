<template>
    <div class="page_content">
        <!-- <a-spin size="large" v-show="loading" /> -->
        <div class="page_edit_content">
            <Form ref="form" :form_data="form_data" @handleSubmit="handleSubmit" @handleChange="handleChange">
                <div slot="order_no">
                    {{order_info.order_no}}
                </div>
                <div slot="customer_name">
                    {{order_info.customer_name}}
                </div>
                <div slot="distributor_name">
                    {{order_info.distributor_name}}
                </div>
                <div slot="type">
                    {{order_info.type == 1 ? '现结订单' : '预付款订单'}}
                </div>
                <div slot="goods_data">
                    <a @click="add_goods()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">添加品项</span>
                    </a>
                    <a-input type="hidden" name="goods_data" v-decorator="['goods_data', goods_datas]" />
                    <div>
                        <ul>
                            <li v-for="(item, index) in goods_datas" :key="index">
                                <div>
                                    <span>品项：{{ item.goods_title }}</span>
                                    <span>类型：{{item.type == 1 ? '本品' : (item.type == 2 ? '促销' : '搭赠')}}</span>
                                    <span>数量：{{ item.num }}</span>
                                    <span>金额：{{item.pay_total}}</span>
                                </div>
                                <a @click="del_contact(item)">删除</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Form>
        </div>

        <EditPop @handleSubmit="contact_submit" :form_data="form_data_contact" :visible.sync="goods_visible"></EditPop>
    </div>
</template>

<script>
    import {
		orderInfo_new,
        editOrder
	} from '@/api/order.js'
    import {getGoods} from '@/api/cost'
    import Form from "@/components/Form";
    import EditPop from "@/components/EditPop";

    export default {
        components: {
            Form,
            EditPop,
        },
        data() {
            return {
                order_info:{},
                goods_datas:[],
                goods_old:[],
                list: [],
                loading: true,
                goods_visible:false,
                form_data_contact: {
                    title: "添加物料",
                    list: [{
                            type: "select",
                            name: "goods_id",
                            title: "品项",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择品项"
                                }]
                            },
                            list: []
                        },
                        {
                            type: "select",
                            name: "type",
                            title: "类型",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择类型"
                                }]
                            },
                            list: [
                                {
                                    key: 1,
                                    value: "本品"
                                },
                                {
                                    key: 2,
                                    value: "促销"
                                },
                                {
                                    key: 3,
                                    value: "搭赠"
                                },
                            ]
                        },
                        {
                            type: "text",
                            name: "num",
                            title: "数量",
                            placeholder: "数量",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写数量"
                                }]
                            }
                        },
                        {
                            type: "text",
                            name: "pay_price",
                            title: "单价",
                            placeholder: "单价",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写单价"
                                }]
                            }
                        },
                    ]
                },
                form_data: {
                    title: "基本信息",
                    show_submit_btn: true,
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: "text",
                            name: "id",
                            hidden: true,
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "slot",
                            name: "order_no",
                            title: "订单编号",
                        },
                        {
                            type: "slot",
                            name: "customer_name",
                            title: "下单客户",
                        },
                        {
                            type: "slot",
                            name: "distributor_name",
                            title: "上级配送商",
                        },
                        {
                            type: "slot",
                            name: "type",
                            title: "订单类型",
                        },
                        {
                            type: "text",
                            name: "remarks",
                            title: "备注",
					        options: {
					            initialValue: ""
					        },
                        },
                        {
                            type: "slot",
                            name: "goods_data",
                            title: "物料",
                            list:[]
                        },
                    ]
                },
            }
        },
        async created() {
              this.getDetail(); 
        },
        methods: {
            getDetail() {
                orderInfo_new({
                    data: {
                        id: this.$route.query.id,
                    }
                }).then(res => {
                    this.order_info = res.data.data.order_data
                    this.form_data.list.forEach(item => {
                        if (item.type == 'slot') {
                            
                        } else {
                            item.options.initialValue = this.order_info[item.name];
                        }
                    })
                    this.goods_datas = res.data.data.goods_data
                })
            },
            get_goods() {
                getGoods({
                    data: {
                        type: 'launch'
                    }
                }).then(res => {
                    let goods = [];
                    this.goods_old = res.data.list
                    res.data.list.forEach(item => {
                        var obj = {key:item.id,value:item.goods_title}
                        goods.push(obj);
                    })
                    this.form_data_contact.list[0]['list'] = goods
                })
            },
            add_goods() {
                this.goods_visible = true;
                this.get_goods();
            },
            contact_submit(e) {
                let value = e.values;
                var goods = {}
                this.goods_old.forEach(item=>{
                    if (item.id == value.goods_id) {
                        item.pay_total = parseFloat(value.pay_price * value.num).toFixed(2)
                        item.pay_price = value.pay_price
                        item.num = value.num
                        return goods = item
                    }
                })
                var new_obj = Object.assign(value,goods);
                new_obj.pay_total = new_obj.pay_total
                this.goods_datas.push(new_obj)
                this.goods_visible = false;
            },
            del_contact(params) {
                this.goods_datas.forEach((item, index) => {
                    if (params == item) {
                        this.goods_datas = this.goods_datas.slice(0, index).concat(
                                this.goods_datas.slice(index + 1, this.goods_datas.length)
                            );
                    }
                });
            },
            handleSubmit(e) {
                editOrder({
                    data:{
                        ...e.values,
                        id:this.$route.query.id,
                        goods_data:this.goods_datas
                    }
                }).then(res=> {
                    this.$router.go(-1);
                })
            },
            handleChange(e) {
            },
        },
    };
</script>
<style lang="less" scoped>
    .map_select {
        position: relative;

        .serach_input {
            position: absolute;
            top: 20px;
            left: 20px;
        }
    }

    ul {
        padding: 0;
    }

    li {
        display: block;
        list-style: none;
        display: flex;

        span {
            padding-right: 10px;

            display: inline-block;
        }
    }

    .avatar-uploader>.ant-upload {
        width: 128px;
        height: 128px;
    }

    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .spin-content {
        border: 1px solid #91d5ff;
        background-color: #e6f7ff;
        padding: 30px;
    }

    .distributor {
        width: 60%;
        margin-left: 1.5%;
        padding: 5px 10px;
        padding-bottom: 10px;
    }

    .distributor_box {
		background-color: rgba(250, 250, 250, 1);
		margin-left: 14%;
		padding-bottom: 20px;
		.title{
			margin-left: 24px;
		}
		/deep/.ant-col-5{
			width: 7%;
		}
    }
</style>
